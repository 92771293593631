import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="box-footer">
        <div className="footer">
          <div className="contacme">
            <span>Desarrollado por: Marco Atencio 2020</span>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
