import React from "react";

export const Welcome = () => {
  return (
    <div className="box-welcome">
      <h1 className="title is-size-1 has-text-white-bis">
        Biblioteca de las peliculas
      </h1>
    </div>
  );
};
